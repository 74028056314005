@import "sizes";
@import "fontSizeUtility";

.loginInputTextFieldContainer {
  width: 100%;
  margin-top: 1vw;
  position: relative;
  > .loginInputTextField {
    width: 100%;

    > .title {
      @include font_size(b03);
      margin-bottom: 0.5vw;
      @include size500 {
        margin-bottom: 1rem;
      }
    }
    > input {
      width: 100%;
      height: 2vw;
      padding-left: 3%;
      @include font_size(b04);

      &::placeholder {
      }

      @include size500 {
        height: 3.5rem;
      }
    }
  }
  .errorContainer {
    display: inline-block;
    position: absolute;
    content: "";
    left: calc(100% + 10px);
    top: 50%;
    width: max-content;
    border: 1px solid #dd4f00;
    text-decoration: none;
    border-radius: 2px;
    padding: 0.5rem 0.5rem;
    @include font_size(b05);

    // margin-left: calc(100% + 10px);

    // margin-top: 10px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -20px;
      bottom: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right-color: #dd4f00;
      transform: translateY(50%);
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -20px;
      bottom: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right-color: white;
      transform: translateX(1px) translateY(50%);
    }
  }
}
