@for $i from 1 through 10 {
  .colSpan#{$i} {
    grid-column-end: span #{$i};
  }
  .colStart#{$i} {
    grid-column-start: #{$i};
  }
  .rowSpan#{$i} {
    grid-row-end: span #{$i};
  }
  .rowStart#{$i} {
    grid-row-start: #{$i};
  }
}
