@import "fontSizeUtility";
@import "componentUtilities";
.login_module {
  > .login_left {
    > .resetLink {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .resetLink1 {
        height: fit-content;
        width: 40%;

        @include login-module-title-element;

        > .mainBlock {
          @include login-mainBlock-styles;
          > .title {
            margin-top: 1vw;
            @include font_size(h04);
            font-weight: 500;
          }
          .twoFAContainer {
            margin-top: 1rem;
            width: 100%;
            @include font-size(b03);
            .element {
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 0.5rem;
              height: 2vw;
              width: 100%;
              border: 1px solid #000;
              border-radius: 0.15vw;
              overflow: hidden;
              .text {
                @include font-size(b04);
                padding-left: 0.3rem;
              }

              .copyIcon {
                position: absolute;
                cursor: pointer;
                border: 1px solid #000;
                top: -1px;
                right: -1px;
                height: 2vw;
                width: auto;
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 350ms ease-in-out;
                background-color: #fff;
                @include size500 {
                  height: 2.5rem;
                }

                svg {
                  width: 80%;
                  height: 80%;
                  path {
                    transition: all 350ms ease-in-out;
                  }
                }
                &:hover {
                  background-color: #f7f7f7;
                  svg {
                    path {
                      fill: #4b4ded;
                    }
                  }
                }
              }
            }
          }

          @include login-module-desc;
          @include login-module-button-styles("totpButton", "totp");
          @include login-module-mismatch;
        }
      }
    }
  }
}
