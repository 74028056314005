@import "fontSizeUtility";
@import "componentUtilities";

.login_module {
  > .login_left {
    > .resetPasswordSuccess {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .resetPasswordSuccess1 {
        @include login-module-title-element;
        > .mainBlock {
          @include login-mainBlock-styles;
          @include size500 {
            min-height: 40vh;
          }

          .imgContainter {
            width: 25%;
            margin-top: 1.9vw;
            img {
              width: 100%;
            }
          }
          > .title {
            margin-top: 1vw;
            @include font_size(h04);
            font-weight: 600;
            color: #0048b2;
          }
          @include login-module-desc;
          @include login-module-button-styles("continueButton", "continue");
        }
      }
    }
  }
}
