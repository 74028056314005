@import "sizes";
.login_module {
  height: 100vh;
  width: 100vw;
  display: flex;
  > .login_left,
  > .login_right {
    width: 50%;
    height: 100%;
    @include size500 {
      width: 100%;
    }
  }
  > .login_left {
    @include size500 {
      z-index: 10;
    }
  }

  > .login_right {
    position: relative;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    div {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    canvas {
      width: 100%;
      height: 100%;
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
    }
    @include size500 {
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0.4;
    }
  }
}
