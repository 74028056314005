@import "fontSizeUtility";
@import "componentUtilities";

.login_module {
  > .login_left {
    > .twoFA {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .twoFA1 {
        height: fit-content;
        width: 40%;
        @include size500 {
          width: 90%;
        }
        @include login-module-title-element;
        > .mainBlock {
          @include login-mainBlock-styles;

          > .passwordImg {
            margin-top: 1vw;
            width: 20%;
            img {
              width: 100%;
            }
          }
          > .forgotTwoFASection {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 1vw;
            @include font_size(b03);
            .forgotTwoFA {
              color: #3a57e8;
            }
          }
          @include login-module-button-styles("continueButton", "continue");
          @include login-module-mismatch;
        }
      }
    }
  }
  > .login_right {
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
