@import "fontSizeUtility";
@import "componentUtilities";

.login_module {
  > .login_left {
    > .resetPassword {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .resetPassword1 {
        @include login-module-title-element;
        > .mainBlock {
          @include login-mainBlock-styles;
          > .title {
            @include font_size(h04);
            margin-top: 1vw;
            font-weight: 600;
          }

          @include login-module-desc;
          @include login-module-button-styles("continueButton", "continue");
          @include login-module-mismatch;
        }
      }
    }
  }
}
