@import "fontSizeUtility";
@import "componentUtilities";

.login_module {
  > .login_left {
    > .resetPasswordSuccessLink {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .resetPasswordSuccessLink1 {
        @include login-module-title-element;
        > .mainBlock {
          @include login-mainBlock-styles;

          .imgContainter {
            width: 25%;
            margin-top: 1.9vw;
            img {
              width: 100%;
            }
          }

          @include login-module-desc;
          @include login-module-button-styles("continueButton", "continue");
        }
      }
    }
  }
}
