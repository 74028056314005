@import "sizes";
@import "fontSizeUtility";
@import "componentUtilities";

.login_module {
  > .login_left {
    > .lockScreen {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .lockScreen1 {
        @include login-module-title-element;
        > .mainBlock {
          @include login-mainBlock-styles;
          @include size500 {
            min-height: 40vh;
          }
          > .profileImg {
            margin-top: 1vw;
            margin-bottom: 0.5vw;
            width: 35%;
            img {
              width: 100%;
            }
          }
          > .name {
            @include font_size(h01);
            font-weight: 500;
          }

          @include login-module-desc;
          @include login-module-button-styles("loginButton", "login");
          @include login-module-mismatch;
        }
      }
    }
  }
}
