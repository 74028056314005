@import "fontSizeUtility";
.wholeContainer {
  display: flex;
  width: 100%;
  min-height: 30vh;
  justify-content: flex-end;

  .navContainer {
    width: 15%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .mainContainer {
    width: 85%;
    height: 100%;
    min-height: 100vh;
    background: #f9f9f9;
  }
}
