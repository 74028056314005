@import "sizes";
@import "fontSizeUtility";
@mixin page-title-properties {
  @include font_size(h03);
  font-weight: 500;
}
@mixin widgets-title {
  @include font_size(b03);
  color: #1e1f5f;
  margin-bottom: 0.3vw;
}

@mixin table-header-styles($bg: rgb(75, 77, 237, 0.15)) {
  background-color: $bg;
  color: #1e1f5f;
}
@mixin table-delete-button-hover {
  transition: all 150ms ease-in-out;
  &:hover {
    color: #2d2e8e;
    letter-spacing: 0.02em;
  }
}

@mixin title-bg-image {
  background-image: url("../images/common/graphicDesign.png");
  width: 100%;
  height: 15vh;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  object-fit: cover;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  color: #fff;
  padding-left: 3vw;
}

@mixin title-image-below-properties {
  position: relative;
  background: #fff;
  width: calc(100% - 5rem);
  margin: 0 auto;
  border-radius: 1rem;
  margin-top: -2vh;
  min-height: 70vh;
  box-shadow: 0px 0px 26px 3px rgba(0, 0, 0, 0.47);
}
@mixin login-module-button-styles($parentDiv, $childButton) {
  > .#{$parentDiv} {
    margin-top: 1.2vw;
    width: 60%;

    @include size500 {
      width: 80%;
    }
    > .#{$childButton} {
      @include font_size(b04);
      width: 100%;
      aspect-ratio: 5;
      padding: 1rem;
      background-color: #4b4ded;
      cursor: pointer;
      color: #eee;
      border: none;
      outline: none;
      border-radius: 4vw;
      transition: all 350ms ease-in-out;
      &:hover {
        background-color: #2d2db2;
      }
      @include size500 {
        aspect-ratio: 8;
        // width: 80%;
      }
    }
  }
}

@mixin login-module-title-element {
  height: fit-content;
  width: 40%;
  @include size500 {
    width: 90%;
  }

  > .companyContent {
    @include font_size(h02);
    display: flex;
    align-items: center;
    > .designElement {
      width: 1.8vw;
      margin-right: 0.5vw;
      display: flex;
      justify-content: center;
      align-items: center;
      @include size500 {
        width: 5vw;
        margin-right: 2.5vw;
      }
      img {
        width: 100%;
      }
    }
  }
}

@mixin login-mainBlock-styles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include size500 {
    min-height: 50vh;
    justify-content: space-evenly;
  }
}

@mixin button-styles {
  @include font_size(b03);
  padding: 0.4vw 1.5vw;
  background-color: #4b4ded;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 0.3vw;
  letter-spacing: 0.05em;
  outline: none;
  cursor: pointer;
  transition: all 350ms ease-in-out;
  &:focus,
  &:hover {
    background-color: #2d2db2;
  }
  &:disabled {
    background-color: #eeeeee;
    border-color: #eeeeee;
    color: #bbbbbb;
    cursor: no-drop;
  }
}
@mixin scroll-bar-design {
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-track {
    width: 15px;
    border-radius: 8px;
    border: solid 5px transparent;
  }

  &::-webkit-scrollbar-thumb {
    transition: all 3500ms linear;
    box-shadow: inset 10px 10px 4px #c9c9c9;
    border: solid 5px transparent;
    border-radius: 8px;
  }
}

@mixin login-module-desc {
  > .desc {
    @include font_size(b05);
    line-height: 140%;

    text-align: center;
    margin-top: 0.7vw;
    color: #8a92a6;
    @include size500 {
      @include font_size(b04);
      color: #fff;
      line-height: 150%;
    }
  }
}

@mixin login-module-mismatch {
  > .misMatch {
    width: 100%;
    margin-top: 1vw;
    padding: 0.1vw 0 0.1vw 5%;
    @include font_size(b05);
    background-color: rgba(255, 0, 0, 0.03);
    color: #f00;
  }
}

@mixin add-button-styles {
  border: 2px solid #4b4ded;
  background-color: #4b4ded;
  color: #fff;
  border-radius: 3px;
  padding: 0.4vw 0.8vw;
  @include font_size(b02);
  font-weight: 500;
  transition: all 250ms ease-in-out;
  &:hover {
    background-color: #fff;
    color: #4b4ded;
  }
  &:disabled {
    background-color: #eee;
    border-color: #eee;
    color: #aaa;
    cursor: not-allowed;
  }
}

@mixin title-ADD-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include font_size(b01);
  }
  .actionContainer {
    display: flex;
    align-items: center;
    .button {
      margin-right: 3vw;
      cursor: pointer;
      @include add-button-styles();
    }
    .icon {
      width: 3vw;
      height: 3vw;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 500ms ease-in-out;
      svg {
        width: 20%;
        height: 20%;
        transition: all 150ms ease-in-out;
      }
      &:hover {
        svg {
          transform: scale(1.3);
        }
      }
      &.open {
        transform: rotateZ(180deg);
      }
    }
  }
}

@mixin details-container-styles($rows: 3) {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat($rows, calc(1.7rem + 1vw));
  grid-auto-flow: column;
  margin-bottom: 1vw;
  .container {
    @include font_size(b03);
    display: flex;
    .title {
      color: #4b4ded;
    }
    .desc {
      &.address {
        display: grid;
        grid-template-columns: repeat(1, 100%);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
      }
    }
  }
}

@mixin date-range-styles {
  > .dateRangeDetailsContainer {
    width: 60%;
    margin-bottom: 1rem;

    > .dateRangeTitleContainer {
      @include title-ADD-dropdown();
      margin-left: -1rem;
      padding-left: 1rem;
      background-color: #fff;
      transition: background-color 150ms;
      &:hover {
        background-color: #eee;
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }
    position: relative;
    > .dateRangeContainer {
      width: 100%;
      background-color: #fff;
      transform: translateY(100%);
      z-index: 1000;
      position: absolute;
      bottom: -1rem;
      left: 0;
      content: "";
      overflow: hidden;
      padding-top: 0.5vw;
      transition: all 350ms ease-in-out;
      opacity: 1;
      .dateRangeContainer1 {
        width: fit-content;
      }
      &.closed {
        opacity: 0.5;
      }

      .buttonContainer {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 1rem;
        button {
          @include button-styles;
        }
      }
    }
  }
}

@mixin modal-submit-button-styles {
  cursor: pointer;
  @include font_size(b04);
  padding: 4px 12px;
  border-radius: 0.5rem;
  transition: all 150ms ease-in-out;
  &:hover {
    transform: translateY(0.5px);
  }
  &.success {
    background-color: #4b4ded;
    color: #fff;
    border: 2px solid transparent;
    // background-color: #ffffff;
    &:hover {
      background-color: #2d2db2;
    }
    &:disabled {
      background-color: #bbb;
      transform: initial;
      cursor: no-drop;
      &:hover {
        animation: shake 0.4s forwards;
      }
    }
  }
  &.delete {
    background-color: #ff3232;
    color: #fff;
    border: 2px solid transparent;

    &:hover {
      background-color: #cc2414;
    }
  }
  &.cancel {
    border: 2px solid #c1c1c1;
    &:hover {
      border-color: #7b88ff;
      background-color: #dbdfff;
    }
  }
}

@mixin profile-page-heading-styles {
  @include table-header-styles(rgb(75, 77, 237, 0.15));
  @include font_size(b03);
  font-weight: 600;
  line-height: 150%;
  padding-left: 3px;
}
.inp-siz1 {
  .leftSymbolInput {
    input {
      width: calc(2rem + 1vw);
    }
  }
}
.inp-siz2 {
  .leftSymbolInput {
    input {
      width: calc(3rem + 2vw);
    }
  }
}
.inp-siz3 {
  .leftSymbolInput {
    input {
      width: calc(4rem + 3vw);
    }
  }
}
.inp-siz4 {
  .leftSymbolInput {
    input {
      width: calc(5rem + 3.5vw);
    }
  }
}
.inp-siz5 {
  .leftSymbolInput {
    input {
      width: calc(6rem + 4vw);
    }
  }
}
.inp-siz6 {
  .leftSymbolInput {
    input {
      width: calc(7rem + 5vw);
    }
  }
}

@keyframes shake {
  0% {
    transform: translateX(0px) rotate(0deg);
  }

  20% {
    transform: translateX(-4px) rotate(-4deg);
  }

  40% {
    transform: translateX(-2px) rotate(-2deg);
  }

  60% {
    transform: translateX(4px) rotate(4deg);
  }

  80% {
    transform: translateX(2px) rotate(2deg);
  }

  100% {
    transform: translateX(0px) rotate(0deg);
  }
}
