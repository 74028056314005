@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  font-size: 62.5%;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter";
}
/* ::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px grey;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #2d2db2;
}

::-webkit-scrollbar-thumb:hover {
  background: #4b4ded;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;

  background-clip: padding-box;
} */
