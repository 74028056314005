@import "sizes";
@import "fontSizeUtility";
@import "componentUtilities";
.login_module {
  > .login_left {
    > .login_Container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .login_Container1 {
        @include login-module-title-element;

        > .mainBlock {
          @include login-mainBlock-styles;

          > .signInTitle {
            margin-top: 1vw;
            @include font_size(h05);
            font-weight: 500;
          }

          > .signInContent {
            margin-top: 0.6vw;
            @include font_size(b03);
          }
          > .forgotPasswordSection {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 1vw;
            @include font_size(b04);
            .forgotPassword {
              color: #3a57e8;
              cursor: pointer;
            }
          }
          @include login-module-button-styles("signInButton", "signIn");
          @include login-module-mismatch;

          > .newAccount {
            margin-top: 1vw;
            display: flex;
            @include font_size(b05);

            > .link {
              color: #3a57e8;
            }
          }
        }
      }
    }
  }
}
